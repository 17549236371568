import * as React from "react"
import Layout from "../components/layout"
import "../main.scss"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "./tabs.scss"
import Accordion from "../components/accordion"


const AboutPage = () => {
  return (
    <Layout>
      <header id="about">About PeacePoint</header>
      <Tabs>
        <TabList>
          <Tab>About Us</Tab>
          <Tab>Beliefs</Tab>
          <Tab>Testimonials</Tab>
          <Tab>Partner</Tab>
          <Tab>Donate</Tab>
        </TabList>

        <TabPanel>
          <p>At PeacePoint we encourage Christians to restore relationships broken by conflict and equip them to resolve disputes fairly for God&apos;s glory and for their good. We teach biblical principles of Christian Resolution & Reconciliation and make resources available to people and churches facing conflicts and disputes. For those interested in helping others, we provide training, so they can serve as Peace Advocates. When conflicts and disputes arise, we follow a biblically based process to guide those seeking a God honoring resolution in which Peace Advocates provide coaching, mediation and arbitration as needed. We also provide training and administrative support to help church leaders promote and pursue peace in their churches.</p>
          <p>PeacePoint is a Christian ministry based in Charlotte, North Carolina. We are a non-profit corporation with 501(c)(3) tax exempt certification from the Internal Revenue Service. PeacePoint is not affiliated with any particular church or denomination but recognizes biblical principles common to all Christian churches. We cooperate with church leaders, ensuring that they always retain pastoral responsibility and the authority to exercise appropriate oversight.</p>
        </TabPanel>
        <TabPanel>
          <p>The following are the core beliefs that inform and guide us as we serve our Lord and His people.</p>

          <ol>
            <li>We believe in one God, the sovereign Creator and Sustainer of all things, infinitely perfect and eternally existing in three Persons: Father, Son, and Holy Spirit.</li>
            <li>Jesus Christ, the Son, became flesh through His miraculous conception by the Holy Spirit and His virgin birth. He who is true God became true man united in one Person forever. He died on the cross as a sacrifice for our sins. On the third day He arose bodily from the dead, ascended into heaven, where He sits on the right hand of God the Father and is the only Mediator between God and man.</li>
            <li>The Holy Spirit has come into the world to reveal and glorify Christ and to apply the saving work of Christ to our hearts. He convicts us of sin and draws us to Jesus the Savior. Indwelling our hearts, He gives us new life, empowers and imparts gifts to us for service. He instructs and guides us into all truth, and seals us for the day of redemption.</li>
            <li>The Bible is God&apos;s Word to us. It was written by human authors, under the supernatural guidance of the Holy Spirit. It is the supreme source of truth for Christian beliefs and living. Because it is inspired by God, it is the truth without any mixture of error.</li>
            <li>Man was created in the image of God. He sinned by disobeying God. This fall brought all humanity under divine condemnation. Every person is in need of regeneration and renewal by the Holy Spirit.</li>
            <li>Being estranged from God and condemned by our sinfulness, our salvation is wholly dependent upon the work of God&apos;s free grace. It is not the work, in whole or in part, of human works or goodness or religious ceremony.</li>
            <li>God credits His righteousness to those who put their faith in Christ alone for their salvation, thereby justifying them in His sight. Only such as are born of the Holy Spirit and receive Jesus Christ become children of God and heirs of eternal life.</li>
            <li>The true Church is composed of all persons who through saving faith in Jesus Christ and the sanctifying work of the Holy Spirit are united together in the Christian Community.</li>
            <li>Jesus Christ will come again to the earth to judge the living and the dead and to consummate history and the eternal plan of God.</li>
            <li>Jesus calls those He has saved to a life of self-denying love and service. “For we are His workmanship, created in Christ Jesus for good works, which God prepared beforehand that we should walk in them” (Eph. 2:10).</li>
          </ol> 
        </TabPanel>
        <TabPanel>
          <Accordion
          title="Barry B., Mediation Party"
          content="My experience with PeacePoint was extremely positive. I was involved in a two year long legal dispute with my former business partner that had deteriorated to the point of litigation. We were deep into the legal process when my former partner asked me to consider Christian mediation. As a believer I was open to the idea, but as a businessman I was skeptical. My attorneys were also concerned. We had spent a great deal of time, energy, and money preparing a solid case. How would Christian mediation affect our case in the event we didn&apos;t reach a resolution?<br><br>
          Throughout the entire process, everyone with PeacePoint was both professional and compassionate. They displayed empathy for the hurt and pain both parties were experiencing and were very understanding of my initial reservations. The many emails and phone calls and the skype meeting prior to the mediation were a tremendous help. The Peace Advocate we selected to serve as our mediator took the time to hear my concerns and to answer all my questions. He encouraged me to trust God through the Christian mediation process, and on every call, he would pray for healing and reconciliation.<br><br>
          PeacePoint mediation provided an environment in which both parties were able to speak to and listen to each other. We were able to discuss difficult issues in a non-threatening environment. Our mediator did a great job of managing the conversation, asking clarifying questions, and separating the parties when necessary to allow us to work through difficult issues. He shared scripture with us and prayed for us. And then he asked the question, “What do you think God would have you do”? This question challenged me to make a proposal that changed the course of our discussion and ultimately lead to an agreement.<br><br>
          I am very grateful to our mediator and to PeacePoint. I would encourage any Christian who is in the middle of conflict to use the resources available through PeacePoint."
          />
          <Accordion
          title="X. X., Party in Partnership Dissolution Mediation"
          content="PeacePoint Peace Advocate Neil Carmichael mediated the dissolution of a partnership involving two couples – both believers. The two had struggled with their unreconciled conflict and unresolved disputes for eight years. Even though sixteen prior attempts to find resolution and reconciliation were unsuccessful, they tried one last effort before submitting the issues to binding arbitration as required by their partnership agreement. The day of the mediation was the first time in eight years the partners had seen each other. After working virtually non-stop for 9 hours, the matter was settled. As important, the parties were finally freed from the burden of their conflict, as this note that Neil received from one of them reflects.<br><br><em>We would like to thank you for your skillful mediation on Wednesday.</em><br><br><em>As we are more rested now, with the stress of uncertain outcomes behind us, we have been able to reflect upon and appreciate your godly application of professional skills on behalf of both parties in the conflict.</em><br><br><em>God answered many prayers that day. We are so grateful to be able to move forward unimpeded with the mission God gave us.</em><br><br><em>And may God bless you in your mission. May He prosper you as you continue to use the skills He has given you to help others.</em><br><br><em>In Christ,</em>"
          />
          <Accordion
          title="Carey M., Participant in Training"
          content="I pursued PeacePoint training because I constantly find myself in situations where I need to guide an individual, a couple, or a ministry leader in conflict. As a church staff member, I encounter these situations frequently and with little advance warning. I desperately needed some training to help the people in conflict God sends my way.<br><br>
          While training with PeacePoint, I made a surprising discovery; the one who benefits most from peacemaking training is the peacemaker! The confidence and boldness that comes from knowing just the basic Biblical principles for conflict resolution and from having some peacemaking “tools” in hand is huge. Peacemaking principles now come easily to my mind and allow me to provide Godly coaching to those in distress, not just my impressions or personal, experiential advice. I can help them refocus on God and the Bible and think through the next steps He would have them take.<br><br>
          I am amazed at how important and useful God&apos;s peacemaking principles have become in my daily life. The Holy Spirit has used them to help me adjust my attitudes, advise my family and move my spouse back towards grace and peacemaking when he (who is also in ministry) is giving counsel to others over coffee. I am so thankful for the training I received. It has become more important to my ministry, my family and my life than I would have ever imagined."
          />
          <Accordion
          title="Howard B., Senior Pastor"
          content="My situation was very complicated. Church issues involving a number of leaders and members created a rift between members of my family who attended and were also employed by the church. After other efforts to address those problems failed, a member of the church suggested we contact PeacePoint for help. I was willing but not optimistic. We had not spoken to one another in months. Trust and hope were all but gone.  I feared that PeacePoint would just deal with the symptoms and not the roots of our problems. Nothing would be resolved. I was very wrong.<br><br>
          Pursuing peace with PeacePoint was different from the start. Everything about the process was informed and tempered by the Scriptures. I expected all parties would get an opportunity to be heard and to express their issues and concerns, and they did. Our experienced PeacePoint mediators, however, did much more.  They skillfully and patiently guided us to have faith and follow God.  They encouraged each of us to own our own wrongs first. As we trusted God, confessed our sins and forgave one another, the things that had divided us for so long seemed to dissipate. It was hard work, and it took time. Ultimately, we reached a godly resolution of all the issues and are now fully reconciled. Praise God!<br><br>
          Peacemaking blessed my church. Our church&apos;s sense of community was at risk.  Because of who was involved, we needed help from someone who was not “owned” by any church group or organization. We needed someone we could all trust. PeacePoint understood all these dynamics and took delicate but pointed care of us.<br><br>
          Peacemaking healed my family. I didn&apos;t think it would be possible to let go and be freed from years of bitterness towards my own family members. Now I have a new chance to have a life with my family. The Lord showed us that we can trust Him to heal past issues and protect our relationships in the future. My entire family has new hope.  If we fall into the same trap in the future, we can all remember our experience and know that all is not lost.<br><br>
          Peacemaking was gospel ministry to me. The process changed my heart and deepened my faith.  I was challenged to give up my need to lead and control the process, like I often am called to do as pastor. Our mediators freed me to be just another guilty and forgiven, by Jesus, person. It was refreshing and gave me more courage and grace to pastor the church.<br><br>
          I thank God for PeacePoint! I do not know of another group that could have handled our complicated situation as well. I encourage all pastors and church leaders who find themselves caught in conflict to humble themselves and get help. When your health and the health of your church are at stake, call PeacePoint."
          />
          <Accordion
          title="Liz and Pat, Parties"
          content="Life and love had all but disappeared in our marriage.  Pat was worn out.  To him, reconciliation was not a reasonable possibility and divorce was inevitable.  Liz was tired but held out some hope for reconciliation.  Because we are Christians, it was important to both of us that we find a Christian way to approach our problems. That common commitment lead us to pursue Christian mediation with PeacePoint.<br><br>
          We expected the mediation process to be honest and open, and it was.  But it was much more than that.  From the very beginning, our mediator gently but persistently encouraged us to trust God and to be open to His will for us and our marriage.  He was patient and fair with both of us even when we seemed to be at odds over everything.  He worked hard to keep us focused on Christ and the process instead of throwing in the towel and giving up on our marriage. Our mediator encouraged us to be honest with ourselves, each other and with God.  He helped us face our differences and conflicts and did not let us run and hide from them.  As we worked together over time, God softened our hearts and changed our attitudes in ways we could not have imagined when we began.<br><br>
          We know of no other approach to conflict that keeps God at the center of the process.  We firmly believe that had we chosen a secular path, we would be divorced.  Make no mistake, the mediation required time and effort.  It was hard and sometimes painful, but it was all worth it.  God used PeacePoint and our mediator to change our lives and save our marriage.<br><br>
          That was in 2007, and we have been growing closer to God and each other every day since."
          />
          <Accordion
          title="P.W., Party in Mediation"
          content="After years of pain and disappointment, divorce and the courts looked like my only option. My pastor, my counselors and my friends, however, encouraged my husband and me to consider Christian mediation with PeacePoint. I am so thankful that we listened to them.<br><br>
          The mediation sessions met and exceeded all of my expectations. I was apprehensive at first. I was afraid of not being heard. I was worried that I would not feel comfortable talking about my feelings. I really wasn&apos;t sure exactly what to expect. Once we got started, I felt completely safe and was able to talk and share all my concerns. The mediators helped my husband and me to confront and resolve our issues by trusting God and His Word. God used the process to change our hearts and restore our marriage. By the grace of God, the reconciliation I thought was impossible has come.<br><br>
          The world&apos;s message is to look out for yourself and get what you want regardless of the cost to others. In a loving and Godly environment, our PeacePoint mediator helped my husband and me to think and approach our difficult situations and decisions with the Word of God and through prayer. I would recommend PeacePoint to anyone facing conflict or difficult situations. They will provide you with Christ centered, Biblically based guidance you can trust and will help you follow God as you search for solutions to the issues you face."
          />
        </TabPanel>
        <TabPanel>
          <h3>Three Ways You Can Help:</h3>
          <ol>
            <li><strong>Pray with us.</strong> Prayer provides the power for pursuing peace and protection for Peace Advocates.</li>
            <li><strong>Support our work.</strong> Teaching honoraria and service fees provide only a small part of the resources we need to serve Christians and the church. We need and deeply appreciate gifts of any size. All gifts are tax deductible. Visit <a href="/donate">our donation page</a> for more information on giving options.</li>
            <li><strong>Point others to PeacePoint.</strong> Many of our opportunities to serve come from caring Christians who point others to us. Send an email with a link to our website to someone in need or send them our contact information and encourage them to call us. There is no charge for initial discussions regarding our services.</li>
          </ol>
        </TabPanel>
        <TabPanel>
        <section id="donate">
          <div>
            <p>PeacePoint depends on generous support provided by people like you to continue our work. Monetary gifts can be made by mail. If you would like to make a gift of securities or other property, please <a href="/contact" className="link">Contact Us</a> to coordinate that transfer. Honorary and memorial gifts are welcome. PeacePoint is a 501(c)(3) ministry so gifts are tax deductible as allowed by law.</p>
            <br/>
            <hr />
            <br/>
            <p><strong>Give by Mail:</strong> Please send contributions by mail to this address.  We will send you a letter acknowledging your gift for tax purposes.</p>
            <div id="address">
              <p>PeacePoint</p>
              <p>PO Box 833</p>
              <p>Blowing Rock, NC 28605</p>
            </div>
            <p><strong>Thank you for supporting PeacePoint!</strong></p>
          </div>
        </section>
        </TabPanel>
      </Tabs>
    </Layout>
  )
}

export default AboutPage
